import React, { useState, useEffect, useContext, createContext } from "react";

import axios from "axios";
import jwt_decode from "jwt-decode"

import Spinner from "./Spinner";

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthorizationProvider({ children }) {
    const auth = useAuthorization();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useAuthorization() {
    const [user, setUser] = useState(
        localStorage.getItem("token") !== null
    );
    const [username, setUsername] = useState();
    const [name, setName] = useState();
    const [isAdmin, setIsAdmin] = useState(
        localStorage.getItem("token") !== null && jwt_decode(localStorage.getItem("token")).role === "ADMIN"
    );
    const [statusMessage, setStatusMessage] = useState();

    const login = credentials => {
        setStatusMessage(
            <Spinner />
        );

        axios
            .post(
                "/api/v1/session",
                credentials
            )
            .then(
                response => {
                    if (response.data.hasOwnProperty("apiToken")) {
                        setStatusMessage();
                        localStorage.setItem("token", response.data.apiToken);
                        setUser(true);
                    }
                    else {
                        setStatusMessage(
                            "An unexpected error has occured. Please try again later."
                        );
                    }
                }
            )
            .catch(
                error => {
                    setStatusMessage(
                        "Login attempt was rejected by the server. Please verify your user name and password and try again."
                    );
                }
            );
    };

    const createAccount = credentials => {
        setStatusMessage(
            <Spinner />
        );

        axios
            .post(
                "/api/v1/account",
                credentials
            )
            .then(
                response => {
                    if (response.data.hasOwnProperty("apiToken")) {
                        setStatusMessage();
                        localStorage.setItem("token", response.data.apiToken);
                        setUser(true);
                    }
                    else {
                        setStatusMessage(
                            "An unexpected error has occured. Please try again later."
                        );
                    }
                }
            )
            .catch(
                error => {
                    setStatusMessage(
                        "Account creation attempt was rejected by the server. Please enter a new user name and try again."
                    );
                }
            );
    };

    const logout = () => {
        localStorage.clear();
        setUser();
        setName();
        setIsAdmin();
    };

    // Subscribe to user on mount
    // Because this sets state in the callback it will cause any ...
    // ... component that utilizes this hook to re-render with the ...
    // ... latest auth object.
    useEffect(() => {
        if (localStorage.getItem("token") !== null) {
            axios
                .get(
                    "/api/v1/account"
                )
                .then(
                    response => {
                        setUsername(response.data.username);
                        setName(response.data.name);

                        const token = jwt_decode(localStorage.getItem("token"));
                        setIsAdmin(token.role === "ADMIN");
                    }
                )
                .catch(
                    error => {
                        setUsername();
                        setName();
                        setIsAdmin();
                        logout();
                    }
                );
        }
    }, [user]);

    // Return the user object and auth methods
    return {
        user,
        username,
        name,
        isAdmin,
        login,
        createAccount,
        logout,
        statusMessage
    };
}

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token");
        config.headers.authorization = `${token}`;
        return config;
    }
);
