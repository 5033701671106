import React from "react";
import FA from "react-fontawesome";

const Spinner = props => {
  return (
    <span className="text-muted">
      <FA name="refresh" spin />
    </span>
  );
};
export default Spinner
