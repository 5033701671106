import React, { lazy } from "react";

import { useAuth } from "./Authorization";

const PrivateRoutes = lazy(() => import("./PrivateRoutes"));
const PublicRoutes = lazy(() => import("./PublicRoutes"));

const Routing = props => {
    const auth = useAuth();

    return (
        auth.user ? <PrivateRoutes /> : <PublicRoutes />
    );
};
export default Routing
