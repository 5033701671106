import React from "react";

import moment from "moment";
import preval from "preval.macro";
import GitInfo from "react-git-info/macro";

const Version = props => {
  const gitInfo = GitInfo();
  const buildTimestamp = preval`module.exports = new Date().getTime();`;

  return (
    <div className="fixed-bottom small">
      <span className="float-right small text-muted">
        {
          `${gitInfo.branch} (${gitInfo.commit.shortHash}) ${moment(gitInfo.commit.date).format("MM-DD HH:mm:ss")}
          @ ${moment.unix(buildTimestamp / 1000).format("MM-DD HH:mm:ss")}`
        }
      </span>
    </div>
  );
};
export default Version
