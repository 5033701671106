import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";

import Spinner from "./Spinner";

const Loading = props => {
  const delay = 200;
  const [showLoadingIndicator, setLoadingIndicatorVisibility] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoadingIndicatorVisibility(true), delay);

    return () => {
      clearTimeout(timer);
    };
  });

  return showLoadingIndicator ? (
    <Card className="border-0">
      <Card.Body >
        <Card.Text as="h1" className="text-center text-muted">
          <Spinner />
        </Card.Text>
      </Card.Body>
    </Card>
  ) : null;
};
export default Loading
