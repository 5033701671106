import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";

import { AuthorizationProvider } from "./components/Authorization";
import ErrorBoundary from "./components/ErrorBoundry";
import Loading from "./components/Loading";
import Routing from "./components/Routing";
import Version from "./components/Version"

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <ErrorBoundary>
      <AuthorizationProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routing />
          </Suspense>
        </BrowserRouter>
        <Version />
      </AuthorizationProvider>
    </ErrorBoundary>
  );
}

export default App;
